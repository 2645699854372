import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@app/modules/auth/services/auth.service'
import { EventParser, ServerRepresentedEvent, ServerRepresentedEventTiming } from '@app/modules/events/models/event.model'
import { EventService } from '@app/modules/events/services/event.service'
import { Persona } from '@app/modules/personas/interfaces/persona.interface'
import { IRunningJob } from '@app/modules/shared/header/running-jobs/model/running-jobs.interface'
import { ToastrService } from '@app/services/toastr.service'
import { InvitationInfo, InvitationState } from '@invite/models/invitation-info.interface'
import { InviteService } from '@invite/services/invite.service'
import { NbDialogRef } from '@nebular/theme'
import { addSeconds, format, parse } from 'date-fns'
import { forkJoin, timer } from 'rxjs'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators'

/**
 * Main registrant invitation component
 */
@Component({
  selector: 'ngx-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  optOutStatus = { opt_out: 0, notOptOut: 0 }
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * Event invitation state
   */
  public state: InvitationState = InvitationState.EVENT

  public InvitationState = InvitationState
  /**
   * Invitation information model
   */
  invitationInfo: InvitationInfo = {
    eventSlug: '',
    formSlug: '',
    message: '',
    fromEventSlug: '',
    fromFormSlug: '',
    action: '',
    ids: [],
    emails: [],
    selectedTemplate: 1,
    isInvitationModal: true,
    count: 0
  }
  paidEvents: any[] = []
  /**
   * Events array
   */
  events: Partial<ServerRepresentedEvent>[] = []
  /**
   * Event model
   */
  event: Partial<ServerRepresentedEvent>
  /**
   * List of personas associated to the event model
   */
  personas: Partial<Persona>[] = []
  /**
   * Performing an async operation?
   */
  processing = true
  /**
   * Event emitter for updated search term
   */
  searchTermChanged: Subject<string> = new Subject<string>()
  /**
   * Current search term
   */
  searchTerm: string = ''
  /**
   * List of event model timings
   */
  eventTimings = []
  /**
   * List of personas that doesn't contain the generic persona
   */
  personasList: any
  /**
   * Emits the new search term
   * @param $event string
   */
  onSearchTermChangedEvent($event) {
    this.searchTermChanged.next($event)
  }

  selectedPersonaSlug: string

  /**
   * Creates the component and injects it's dependencies
   * @param service InviteService
   * @param eventService EventService
   * @param authService AuthService
   * @param ref NbDialogRef
   */
  constructor(
    private service: InviteService,
    private eventService: EventService,
    public authService: AuthService,
    public ref: NbDialogRef<InvitationComponent>,
    private toastrService: ToastrService,
    private router: Router
  ) {}
  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.loadEvents()
    this.searchTermChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(takeUntil(this._destroy$))
      .subscribe((term) => {
        this.searchTerm = term
        this.loadEvents()
      })

    this.updateOptOutStatus(this.invitationInfo.ids)
  }

  /**
   * Formats a date object to a representable string
   * @param date Date
   * @returns string
   */
  formatDate(date) {
    return date ? format(parse(date, 'yyyy-MM-dd HH:mm:ss', Date.now()), 'dd MMM yyyy') : ''
  }

  /**
   * Loads all elligable events for the current workspace
   */
  loadEvents() {
    this.processing = true
    if (!this.invitationInfo.isInvitationModal) {
      this.events = this.paidEvents
      this.processing = false
      return
    }
    this.eventService
      .getEventsExcept(1, this.searchTerm)
      .pipe(
        takeUntil(this._destroy$),
        map((events) => events.filter((o) => o.slug !== this.invitationInfo.fromEventSlug)),
        map((events) =>
          events.map((event) => {
            return {
              name: event.name,
              slug: event.slug,
              logo: event.logo,
              cover_page: event.cover_page,
              status: event.status,
              social_media: event.social_media,
              id: event.id,
              checkins: event.checkins,
              forms: event.forms
                .map((form) => {
                  return {
                    name: form.name,
                    slug: form.slug,
                    weight: form.weight,
                    enable_payment: form.enable_payment,
                    products_count: form.products_count,
                  }
                })
                .sort((a, b) => a.weight - b.weight),
              public_start_date: this.formatDate(event.public_start_date),
              public_end_date: this.formatDate(event.public_end_date)
            }
          })
        )
      )
      .subscribe((result: any) => {
        this.events = result
        this.processing = false
      })
  }

  /**
   * Gets event and event timings for an event id
   * @param eventId number
   */
  getEventTimings(eventId) {
    forkJoin([this.eventService.getEvent(eventId), this.eventService.getEventTiming(eventId)])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([event, timeResult]: [ServerRepresentedEvent, ServerRepresentedEventTiming[]]) => {
        const eventInfo = EventParser.toResponse(EventParser.compose(event, timeResult))
        this.eventTimings = eventInfo.event_timing
      })
  }
  /**
   * Sets the current event and gets it's timings, updates the personas list and invitation object
   * @param event Event
   */
  setEvent(event: ServerRepresentedEvent) {
    if (this.invitationInfo.isInvitationModal) {
      this.getEventTimings(event.id)
      this.event = event
      this.setPersonas(this.event.forms)
      this.invitationInfo.eventSlug = event.slug
      this.state = InvitationState.PERSONA
    } else {
      this.ref.close()
      this.router.navigate([`/wizard/update/${event.id}`])
    }
  }
  /**
   * Sets the current personas list
   * @param personas Persona[]
   */
  setPersonas(personas) {
    this.personasList = personas.filter((o) => {
      return o.slug !== 'GEN' && o.slug !== 'generic'
    })
  }
  /**
   * sets the selected persona
   * @param personaSlug string
   */
  setPersona(personaSlug: string) {
    this.selectedPersonaSlug = personaSlug
    this.invitationInfo.formSlug = personaSlug
    this.state = InvitationState.MESSAGE
  }
  /**
   * Invites registrants using the provided invitation info model.
   * If no argument is provided, it uses the invitationInfo property of the component.
   *
   * @param {InvitationInfo} [invitationInfo] - The invitation info model to be used for the invitation.
   * If not provided, the component's invitationInfo property is used.
   */
  invite(invitationInfo?: InvitationInfo, testEmail: boolean = false) {
    // If no argument is provided, use the invitationInfo property of the component
    if (!invitationInfo) {
      invitationInfo = this.invitationInfo
    }
    this.processing = true
    this.service.invite(invitationInfo).subscribe(
      (result) => {
        this.processing = false
        if (testEmail) {
          this.toastrService.success('Sent', `Test email has been sent to ${this.authService.user.email}`)
        } else {
          this.state = InvitationState.COMPLETE
          // Get running jobs after a successful invitation
          timer(6000).subscribe(() => {
            this.service.getRunningJobs(this.authService.currentWorkspace.slug).subscribe((jobs: IRunningJob[]) => {
              // Set the running jobs in the authService
              this.authService.updateRunningJobs(jobs)
            })
          })
        }
      },
      (error) => {
        this.processing = false
        this.state = InvitationState.COMPLETE
      }
    )
  }

  /**
   * Creates a copy of the invitationInfo property of the component and modifies it for a test invitation.
   * The copied object's emails array is appended with the user's email, the action property is set to 'test',
   * and the ids array is emptied. Then invites registrants using the modified invitation info model.
   */
  inviteTest() {
    const testInvitationInfo: InvitationInfo = { ...this.invitationInfo }

    // Modify the properties of the copied object
    testInvitationInfo.emails = [...testInvitationInfo.emails, this.authService.user.email]
    testInvitationInfo.action = 'test'
    testInvitationInfo.ids = []
    testInvitationInfo.count = 1

    this.invite(testInvitationInfo, true)
  }
  /**
   * Navigates to a previous state based on the current state
   */
  back() {
    this.state === InvitationState.MESSAGE ? (this.state = InvitationState.PERSONA) : (this.state = InvitationState.EVENT)
  }

  updateOptOutStatus(ids: string[]) {
    this.eventService
      .getOptOutStatus(this.authService.currentWorkspace.slug, this.invitationInfo, ids)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.optOutStatus = response
      })
  }
}

