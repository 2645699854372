import { Component, Input, OnInit } from '@angular/core'
import { AuthService } from '@app/modules/auth/services/auth.service'
import { ServerRepresentedEvent } from '@app/modules/events/models/event.model'
import { InvitationInfo } from '../../models/invitation-info.interface'
import { MatTabChangeEvent } from '@angular/material/tabs'

@Component({
  selector: 'ngx-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  /**
   * Event model
   */
  @Input()
  event: Partial<ServerRepresentedEvent>
  selectedValue: number
  workspaceName: string = ''
  /**
   * Invitation info model
   */
  @Input()
  invitationInfo: Partial<InvitationInfo>
  /**
   * Event timings list
   */
  @Input()
  eventTimings: []

  /**
   * Current workspace logo
   */
  @Input()
  workspaceLogo: string = ''
  @Input()  selectedPersonaSlug: string

  constructor(private authService: AuthService) {
    this.workspaceName = this.authService.currentWorkspace.name
  }

  onTabChange(event: MatTabChangeEvent) {
    this.invitationInfo.selectedTemplate = event.index + 1
  }

  ngOnInit(): void {}
}
