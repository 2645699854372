import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { AuthService } from '@app/modules/auth/services/auth.service'
import { environment } from '@environments/environment'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { InvitationInfo } from '../models/invitation-info.interface'
import { IRunningJob } from '@app/modules/shared/header/running-jobs/model/running-jobs.interface'

/**
 * Service used to invite registrants to a different event under the same workspace
 */
@Injectable({
  providedIn: 'root'
})
export class InviteService implements OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * Creates the service and injects it's dependencies
   * @param http HttpClient
   * @param auth AuthService
   */
  constructor(private http: HttpClient, private auth: AuthService) {}
  /**
   * Invites registrants to a new event that belongs to the current workspace
   * @param info Invitation information
   * @returns Observable
   */
  invite(info: InvitationInfo) {
    return this.http
      .patch(`${environment.registrationsApi}invite-bulk/${this.auth.currentWorkspace.slug}/${info.fromEventSlug}/${info.fromFormSlug}`, {
        action: info.action,
        ids: info.ids,
        invite_message: info.message,
        invite_event: info.eventSlug,
        invite_form: info.formSlug,
        emails: info.emails,
        email_template: info.selectedTemplate,
        count: info.count
      })
      .pipe(takeUntil(this._destroy$))
  }

  /**
   * Get the count of the unsettled registrants
   * @param info Invitation information
   */
  unsSettledCount(info: Pick<InvitationInfo,'action'|'ids'|'eventSlug'|'formSlug'>):Observable<any>{
    return this.http.post(`${environment.registrationsApi}count/unsettled/${this.auth.currentWorkspace.slug}/${info.eventSlug}/${info.formSlug}`,{
      action:info.action,
      ids:info.ids,
    })
  }

  getRunningJobs(workspace_slug: string): Observable<IRunningJob[]> {
    return this.http.get<IRunningJob[]>(`${environment.planningDashboard}statistics/${workspace_slug}`)
  }
}
