<div class="template-container">
  <div class="event-details d-flex flex-column justify-content-center align-items-center">
      <span class="invitation-text">{{isPaidForm? 'Join us at' : 'You have been invited to attend'}}</span>
    <span class="event-name">{{ event.name | titlecase }}</span>
    <span class="workspace-name-container"
      >By <span class="workspace-name">{{ workspaceName | titlecase }}</span></span
    >
  </div>
  <ngx-editor [border]="true" [editorPadding]="true" [placeholder]="'Type your invitation message'" [invitationInfo]="invitationInfo" [showMenuBar]="true"> </ngx-editor>

  <ngx-landing-header [isInvitation]="true" [isLandingPage]="false" [event]="event"></ngx-landing-header>

  <div class="event-dates">
    <div class="date-header">
      <img src="https://res.cloudinary.com/contactless/image/upload/v1618317771/uploaded_public_files/swq2m3hzq3lokmctrkjb.svg" alt="timing icon" />
    </div>
    <div class="dates">
      <div class="title">{{ getDisplayDate(event.public_start_date, event.public_end_date) }}</div>
      <div class="days-details" *ngFor="let date of eventTimings">
        <span class="day">{{ getDisplayDay(date.day) }}</span>
        <span class="month">{{ getDisplayMonth(date.day) }}</span>
        <span class="timings">{{ date.start_datetime }} - {{ date.end_datetime }}</span>
        <span class="day-info" *ngIf="date.info">— {{ date.info }}</span>
      </div>
      <div class="add-to-calendar">
        <span>Add to Calendar</span>
      </div>
    </div>
  </div>
  <div class="map-box">
    <ngx-mapbox-gl [event]="event" [addPadding]="false" [height]="294" [zoom]="10"></ngx-mapbox-gl>
  </div>
  <p class="text-center template-footer">Opt-out from future event by {{ event.name }}</p>
</div>
